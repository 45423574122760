<template>
  <!-- <div class="m_nav">
      <div class="m_nav_box">
            <ul class="nav-box" >
              <li><router-link to="/ljsyy">简介</router-link></li>
              <li><router-link tag="a" target="_blank" to="/downloadApp">客户端下载</router-link></li>
              <li><router-link tag="a" target="_blank" to="/downloadUseManual">使用手册下载</router-link></li>
            </ul>
      </div>
    </div> -->
  <DetailsContainer :title="title" :content="content" />
</template>

<script>
import DetailsContainer from "@/views/layout/DetailsContainer";
import { onMounted, reactive, toRefs } from "vue";
import axios from "@/api/apis";

export default {
  name: "City",
  components: { DetailsContainer },
  setup() {
    const state = reactive({
      title: "市级技术平台",
      content: "",
    });
    const loadCity = () => {
      axios
        // .post("/city")
        .post("https://cqgdrm.com/api/website/city")
        .then(function (data) {
          state.title = data.title;
          state.content = data.content;
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    onMounted(() => {
      loadCity();
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style scoped>
</style>